<template>
  <div>
    <b-alert
      v-if="programs.length < 1"
      v-model="showDismissibleAlert"
      class="p-1"
      dismissible
      variant="dark"
    > Currently, you are not enrolled in any upcoming events. Explore available opportunities in the 'Open for Registration' section below.
    </b-alert>
    <b-tabs
      v-if="$ability.can('manage', 'User')"
      fill
      pills
      class="shadow p-2"
    >
      <b-row
        v-if="!getIncubatorFromSubdomain()"
        class="px-1 mb-2"
      >
        <b-col
          md="4"
          :class="tab==2?'selected-top-tab':'deselected-top-tab'"
          @click="tab=2"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
          <span>Browse Events</span>
        </b-col>
        <b-col
          md="4"
          :class="tab==3?'selected-top-tab':'deselected-top-tab'"
          @click="tab=3"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
          <span>My Applications</span>
        </b-col>
        <b-col
          md="4"
          :class="tab==1?'selected-top-tab':'deselected-top-tab'"
          @click="tab=1"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
          <span>My Events</span>
        </b-col>
      </b-row>
      <b-row
        v-else
        class="px-1 mb-2"
      >
        <b-col
          md="4"
          :class="tab==1?'selected-top-tab':'deselected-top-tab'"
          @click="tab=1"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
          <span>My Events</span>
        </b-col>
        <b-col
          md="4"
          :class="tab==2?'selected-top-tab':'deselected-top-tab'"
          @click="tab=2"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
          <span>Browse Events</span>
        </b-col>
        <b-col
          md="4"
          :class="tab==3?'selected-top-tab':'deselected-top-tab'"
          @click="tab=3"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
          <span>My Applications</span>
        </b-col>
      </b-row>
      <div class="d-flex filters-box mt-1 mb-2">
        <div class="fl-col-1">
          <span>Filters</span>
        </div>
        <div class="fl-col-2 d-flex justify-content-start">
          <b-navbar-nav
            v-if="!getIncubatorFromSubdomain()"
            class="nav align-items-start mr-50"
          >
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[0].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[0].open=!filters[0].open"
                >
                  <span>{{ filters[0].title }}</span>
                  <div
                    v-if="filters[0].selected != null"
                    :class="`${filters[0].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[0].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[0].options"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[0].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[0].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[0].selected && filters[0].selected === option"
                      @change="selectFilter(0, 'Single', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[1].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[1].open=!filters[1].open"
                >
                  <span>{{ filters[1].title }}</span>
                  <div
                    v-if="filters[1].selected != null"
                    :class="`${filters[1].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[1].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[1].options"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[1].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[1].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[1].selected && filters[1].selected === option"
                      @change="selectFilter(1, 'Single', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
                <div
                  v-if="filters[1].selected && filters[1].selected.value === 'custom'"
                  class="d-flex justify-content-between px-1 mt-50"
                >
                  <b-form-group
                    class="mb-0 mr-50"
                    label="Min"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model="rangeSlider.min"
                      placeholder="Min"
                      type="number"
                      min="0"
                      size="sm"
                    />
                  </b-form-group>
                  <b-form-group
                    class="mb-0"
                    label="Max"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model="rangeSlider.max"
                      placeholder="Max"
                      type="number"
                      max="120"
                      size="sm"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[2].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[2].open=!filters[2].open"
                >
                  <span>{{ filters[2].title }}</span>
                  <div
                    v-if="filters[2].selected != null"
                    :class="`${filters[2].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[2].selected.length ? filters[2].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[2].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[2].options"
                style="max-height:400px; overflow-y: scroll;"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[2].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[2].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[2].selected && filters[2].selected.indexOf(option) >= 0"
                      @change="selectFilter(2, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[11].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[11].open=!filters[11].open"
                >
                  <span>{{ filters[11].title }}</span>
                  <div
                    v-if="filters[11].selected != null"
                    :class="`${filters[11].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[11].selected.length ? filters[11].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[11].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[11].options"
                style="max-height:400px; overflow-y: scroll; width: 250px;"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[11].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filterIncubators"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[11].selected && filters[11].selected.indexOf(option) >= 0"
                      @change="selectFilter(11, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
        <div class="fl-col-3">
          <b-button
            variant="primary"
            size="sm"
            @click="openAdvanceModal()"
          >
            Advance
          </b-button>
        </div>
      </div>
      <b-overlay
        v-if="tab==1"
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section
          v-if="programs.length > 0"
          class="grid-view"
        >
          <b-row>
            <b-col
              v-for="(program, i) in programs"
              :key="i"
              md="4"
            >
              <b-card
                tag="article"
                header-tag="header"
                class="ecommerce-card"
              >
                <Promised
                  v-if="program && program.image"
                  :promise="getLinkFromS3(program.image)"
                >
                  <template v-slot:pending>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                  <template v-slot="data">
                    <b-aspect
                      :style="`background: url(${data}) center center / contain no-repeat`"
                      aspect="2"
                    />
                  </template>
                  <template v-slot:rejected>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                </Promised>
                <b-card-img
                  v-else
                  class="card-img w-100"
                  src="https://picsum.photos/600/300"
                />
                <b-badge
                  style="position: absolute; top: 80px; right: 30px;color: black;"
                  variant="light"
                >
                  {{ program.type }}
                </b-badge>
                <b-badge
                  style="position: absolute; top: 110px; right: 30px;color: black;"
                  variant="light"
                >
                  Free
                </b-badge>
                <b-badge
                  style="position: absolute; top: 80px; left: 30px;color: black;"
                  variant="light"
                >
                  E
                </b-badge>
                <template #header>
                  <div class="d-flex align-items-center">
                    <b-img
                      rounded
                      alt="Rounded image"
                      src="https://shorturl.at/kxFUX"
                      style="height: 30px; width: 30px; margin-right: 10px;"
                    />
                    <b-card-text> <strong>{{ program.users_organizationtable.title }}</strong></b-card-text>
                  </div>
                </template>
                <!-- <b-container sm="" style="position: absolute; top: 40%; left: 8px;" >
                        <b-img rounded alt="Rounded image" src="https://shorturl.at/kxFUX" style="height: 70px; width: 140px; margin-right: 10px;"></b-img>
                  </b-container> -->
                <div class="mt-2 mb-1">
                  <div>
                    <b-card-text style="font-size: large; margin-top: 15px; margin-bottom: 5px;">
                      <strong> {{ program.title }} </strong>
                    </b-card-text>
                  </div>
                  <div class="d-flex align-items-baseline   justify-content-between">
                    <b-card-text><strong>
                      <feather-icon icon="CalendarIcon" />
                      {{ beginDate(program.begin_date) }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      Online
                    </b-badge>
                  </div>
                  <b-card-sub-title class="mt-1">
                    {{ program.description }}
                  </b-card-sub-title>
                </div>
                <div class="item-options text-center d-flex">
                  <b-link
                    class="btn btn-wishlist btn-light mr-1 "
                    style="width:100%"
                    variant="light"
                    @click="modaldetails = program; $bvModal.show('view-event-modal')"
                  >
                    <span>View Details</span>
                  </b-link>
                  <b-link
                    :to="{ name: 'event-enter', params: { id: program.id } }"
                    class="btn btn-primary btn-cart ml-1"
                    style="width:100%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <b-overlay
        v-if="tab==2"
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="applynow.length < 1"
          class="text-center"
        >
          No programs are currently open for application!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(applyn, i) in applynow"
              :key="i"
              :title="applyn.title"
              md="4"
              style="margin-bottom: 10px"
            >
              <b-card
                :key="i"
                tag="article"
                style="border-radius: 10px; margin-bottom: 0; height: 100%; position: relative;"
              >
                <Promised
                  v-if="applyn && applyn.image"
                  :promise="getLinkFromS3(applyn.image)"
                >
                  <template v-slot:pending>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                  <template v-slot="data">
                    <b-aspect
                      :style="`background: url(${data}) center center / contain no-repeat`"
                      aspect="2"
                    />
                  </template>
                  <template v-slot:rejected>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                </Promised>
                <b-card-img
                  v-else
                  class="card-img w-100"
                  src="https://picsum.photos/600/300"
                />
                <b-badge
                  style="position: absolute; top: 80px; right: 30px;color: black;"
                  variant="light"
                >
                  {{ applyn.type }}
                </b-badge>
                <b-badge
                  style="position: absolute; top: 110px; right: 30px;color: black;"
                  variant="light"
                >
                  Free
                </b-badge>
                <b-badge
                  style="position: absolute; top: 80px; left: 30px;color: black;"
                  variant="light"
                >
                  E
                </b-badge>
                <template #header>
                  <div class="d-flex align-items-center">
                    <b-img
                      rounded
                      alt="Rounded image"
                      src="https://shorturl.at/kxFUX"
                      style="height: 30px; width: 30px; margin-right: 10px;"
                    />
                    <b-card-text> <strong>{{ applyn.users_organizationtable.title }}</strong></b-card-text>
                  </div>
                </template>
                <div class="mt-1 mb-1">
                  <div>
                    <b-card-text style="font-size: large; margin-top: 15px; margin-bottom: 5px;">
                      <strong> {{ applyn.title }} </strong>
                    </b-card-text>
                  </div>
                  <div class="d-flex align-items-baseline mb-1 justify-content-between">
                    <b-card-text><strong>
                      <feather-icon icon="CalendarIcon" />
                      {{ beginDate(applyn.begin_date) }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      Online
                    </b-badge>
                  </div>
                  <b-card-sub-title class="mt-1">
                    {{ applyn.description }}
                  </b-card-sub-title>
                </div>
                <div class="item-options text-center d-flex">
                  <b-link
                    class="btn btn-wishlist btn-light mr-1 "
                    style="width:100%"
                    variant="light"
                    @click="modaldetails = applyn; $bvModal.show('view-event-modal')"
                  >
                    <span>View Details</span>
                  </b-link>
                  <b-link
                    :to="`/startup-portal/events/application/${applyn.id}/${applyn.programs_applicationformstables[0].id}/${userOrgs[0]}`"
                    class="btn btn-primary btn-cart ml-1 "
                    style="width:100%"
                  >
                    <span>Apply Now</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <b-overlay
        v-if="tab==3"
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="!$apollo.loading && applications.length < 1"
          class="text-center"
        >
          No applications found!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(application, j) in applications"
              :key="j"
              :title="application.title"
              md="4"
              style="margin-bottom: 10px"
            >
              <b-card
                :key="i"
                tag="article"
                style="border-radius: 10px; margin-bottom: 0; height: 100%; position: relative;"
              >
                <Promised
                  v-if="application && application.image"
                  :promise="getLinkFromS3(application.image)"
                >
                  <template v-slot:pending>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                  <template v-slot="data">
                    <b-aspect
                      :style="`background: url(${data}) center center / contain no-repeat`"
                      aspect="2"
                    />
                  </template>
                  <template v-slot:rejected>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                </Promised>
                <b-card-img
                  v-else
                  class="card-img w-100"
                  src="https://picsum.photos/600/300"
                />
                <b-badge
                  style="position: absolute; top: 80px; right: 30px;color: black;"
                  variant="light"
                >
                  {{ application.type }}
                </b-badge>
                <b-badge
                  style="position: absolute; top: 110px; right: 30px;color: black;"
                  variant="light"
                >
                  Free
                </b-badge>
                <b-badge
                  style="position: absolute; top: 80px; left: 30px;color: black;"
                  variant="light"
                >
                  E
                </b-badge>
                <template #header>
                  <div class="d-flex align-items-center">
                    <b-img
                      rounded
                      alt="Rounded image"
                      src="https://shorturl.at/kxFUX"
                      style="height: 30px; width: 30px; margin-right: 10px;"
                    />
                    <b-card-text> <strong>{{ application.users_organizationtable.title }}</strong></b-card-text>
                  </div>
                </template>
                <div class="mt-1 mb-1">
                  <div>
                    <b-card-text style="font-size: large; margin-top: 15px; margin-bottom: 5px;">
                      <strong> {{ application.title }} </strong>
                    </b-card-text>
                  </div>
                  <div class="d-flex align-items-baseline mb-1 justify-content-between">
                    <b-card-text><strong>
                      <feather-icon icon="CalendarIcon" />
                      {{ beginDate(application.begin_date) }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      Online
                    </b-badge>
                  </div>
                  <b-card-sub-title class="mt-1">
                    {{ application.description }}
                  </b-card-sub-title>
                </div>
                <div class="item-options text-center d-flex">
                  <b-link
                    class="btn btn-wishlist btn-light mr-1 "
                    style="width:100%"
                    variant="light"
                    @click="modaldetails = application; $bvModal.show('view-event-modal')"
                  >
                    <span>View Details</span>
                  </b-link>
                  <b-link
                    :to="`/startup-portal/events/applications/rounds/${application.id}/${application.programs_applicantstables[0].current_application_form_id}/${application.programs_applicantstables[0].id}`"
                    class="btn btn-primary btn-cart ml-1 "
                    style="width:100%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tabs>
    <b-tabs
      v-else-if="$ability.can('manage', 'Mentor')"
      fill
      pills
      class="shadow p-2"
    >
      <div class="d-flex justify-content-between mb-2">
        <h2>My Programs</h2>
        <b-link
          :to="`/mentor-portal/programs`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorprograms.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(mentorprogram, i) in mentorprograms"
              :key="i"
              :title="mentorprogram.title"
              md="4"
            >
              <b-card
                :key="i"
                :title="mentorprogram.title"
                class="ecommerce-card"
                img-alt="card img"
                img-top
                no-body
                style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              >
                <div
                  class="px-2 py-1 bg-primary"
                  style="border-radius: 10px 10px 0 0;"
                >
                  <b-card-title class="text-white">
                    {{ mentorprogram.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    <span class="text-light">{{ mentorprogram.type }}</span>
                  </b-card-sub-title>
                </div>
                <b-card-body class="p-2">
                  <b-card-text>
                    Begin date: {{ mentorprogram.begin_date }}
                    <br> Capacity: {{ mentorprogram.capacity }}
                    <br> Industry: {{ mentorprogram.industry }}
                  </b-card-text>
                </b-card-body>
                <!-- Product Actions -->
                <div class="item-options text-center d-flex justify-content-end">
                  <b-link
                    v-if="mentorprogram.programs_partnerstables[0].is_jury"
                    :to="`/mentor-portal/programs/rounds/${mentorprogram.id}/${mentorprogram.programs_partnerstables[0].id}`"
                    class="btn btn-wishlist btn-light"
                    variant="light"
                    style="width:50%"
                  >
                    <span>Applications</span>
                  </b-link>
                  <b-link
                    :to="`/mentor-portal/programs/select/${mentorprogram.id}/${mentorprogram.programs_partnerstables[0].id}`"
                    class="btn btn-primary btn-cart"
                    style="width:50%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>Invitations</h2>
        <b-link
          :to="`/mentor-portal-invitations`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card
          v-if="invitations.length < 1"
          class="text-center"
        >
          You don't have any pending invites!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(invitation, i) in invitations"
              :key="i"
              :title="invitation.title"
              md="4"
              style="margin: 10px"
            >
              <b-card
                :key="i"
                :title="invitation.title"
                class="ecommerce-card"
                img-alt="card img"
                img-top
                no-body
              >
                <div
                  class="px-2 py-1 bg-primary"
                >
                  <b-card-title class="text-white">
                    {{ invitation.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    <span class="text-light">{{ invitation.type }}</span>
                  </b-card-sub-title>
                </div>
                <b-card-body class="p-2">
                  <b-card-text>
                    Begin date: {{ invitation.begin_date }}
                    <br> Capacity: {{ invitation.capacity }}
                    <br> Industry: {{ invitation.industry }}
                  </b-card-text>
                </b-card-body>
                <!-- Product Actions -->
                <div class="item-options text-center d-flex">
                  <b-link
                    :to="`/mentor-portal/programs/${invitation.id}`"
                    class="btn btn-wishlist btn-light"
                    variant="light"
                    style="width:50%"
                  >
                    <span>View Details</span>
                  </b-link>
                  <b-link
                    v-if="invitation.users_associationtables[0].status.toLowerCase() === 'invited'"
                    class="btn btn-wishlist btn-primary"
                    variant="light"
                    style="width:50%"
                    @click="selectedIndex = i; $bvModal.show('respond-modal')"
                  >
                    <span>Respond</span>
                  </b-link>
                  <b-link
                    v-else
                    :class="{
                      'btn-success': invitation.users_associationtables[0].status.toLowerCase() === 'accept',
                      'btn-danger': invitation.users_associationtables[0].status.toLowerCase() === 'reject'
                    }"
                    class="btn btn-wishlist"
                    disabled
                    variant="light"
                    style="width:50%"
                    @click="selectedIndex = i; $bvModal.show('respond-modal')"
                  >
                    <span>{{ invitation.users_associationtables[0].status + 'ed' }}</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-modal
            id="respond-modal"
            cancel-title="Reject"
            ok-title="Accept"
            title="Respond to Invitation"
            @cancel="updateInvResponse(false)"
            @ok="updateInvResponse(true)"
          >
            <span
              v-if="selectedIndex !== null"
            >
              Would you like to join <strong class="text-primary">{{
                invitations[selectedIndex].title
              }}</strong> as a <strong
                class="text-primary"
              >{{ invitations[selectedIndex].users_associationtables[0].role }}</strong>?
            </span>
          </b-modal>
          <b-modal
            id="join-incubator-modal"
            class="font-medium-2"
            title="Join Incubator's Network"
            ok-title="Join"
            centered
            @ok="joinIncubator(incubators[0].id)"
          ><span
            v-if="incubators[0]"
          >Would you like to join the network of <strong class="text-primary d-inline-block">{{
            incubators[0].title
          }}</strong> as a mentor?</span>
          </b-modal>
        </section>
      </b-overlay>
    </b-tabs>
    <incubator-dashboard
      v-else-if="$ability.can('manage', 'Incubator')"
    />
    <span v-else>Some error occured. Please try logging out and log in again. </span>
    <b-modal
      id="view-event-modal"
      :title="modaldetails?modaldetails.title:''"
      hide-footer
      size="lg"
    >
      <Promised
        v-if="modaldetails && modaldetails.users_organizationtable && modaldetails.users_organizationtable.logo"
        :promise="getLinkFromS3(modaldetails.users_organizationtable.logo)"
      >
        <template v-slot:pending>
          <b-card-img
            class="card-img"
            src="https://picsum.photos/600/300"
          />
        </template>
        <template v-slot="data">
          <b-aspect
            :style="`background: url(${data}) center center / contain no-repeat`"
            aspect="2"
          />
        </template>
        <template v-slot:rejected>
          <b-card-img
            class="card-img"
            src="https://picsum.photos/600/300"
          />
        </template>
      </Promised>
      <b-card-img
        v-else
        class="card-img w-100"
        src="https://picsum.photos/600/300"
      />
      <b-table
        stacked
        bordered
        :items="[{
          'Title': modaldetails ? modaldetails.title : '',
          'Type': modaldetails ? modaldetails.type : '',
          'Organized By': modaldetails ? modaldetails.users_organizationtable.title : '',
          'Description': modaldetails ? modaldetails.description : '',
          'Duration': modaldetails ? modaldetails.duration : '',
          'Start Date': `${new Date(modaldetails ? modaldetails.begin_date : '')
            .toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZone: 'UTC',
              hour12: false,
            })}`,
          'End Date':`${new Date(modaldetails ? modaldetails.end_date : '')
            .toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZone: 'UTC',
              hour12: false,
            })}`,
          'Capacity': modaldetails ? modaldetails.capacity : '',
          'Status': modaldetails ? modaldetails.status : '',
          'Industry': modaldetails ? modaldetails.industry : '',
          'Lifecycle Stage': modaldetails ? modaldetails.ideal_lifecycle_stage : '',
        }]"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="view-event-application-modal"
      title="Event Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="items"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="view-event-registration-modal"
      title="Event Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="items"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="filterModal"
      ok-only
      no-close-on-backdrop
      size="lg"
      title="Advance Filters"
    >
      <h5><strong>Filters</strong></h5>
      <b-row>
        <b-col
          md="4"
          style="border-right: solid 1px #ebe9f1"
          class="pr-0"
        >
          <h6
            v-if="!getIncubatorFromSubdomain()"
            :class="`d-flex align-items-center mb-0 ${filters[0].selected === null ? 'py-75' : 'py-50'} ${filters[0].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(0)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[0].title }}</span>
              <div
                v-if="filters[0].selected != null"
                :class="`${filters[0].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[11].selected === null ? 'py-75' : 'py-50'} ${filters[11].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(11)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[11].title }}</span>
              <div
                v-if="filters[11].selected != null"
                :class="`${filters[11].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[11].selected.length ? filters[11].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[1].selected === null ? 'py-75' : 'py-50'} ${filters[1].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(1)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[1].title }}</span>
              <div
                v-if="filters[1].selected != null"
                :class="`${filters[1].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[7].selected === null ? 'py-75' : 'py-50'} ${filters[7].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(7)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[7].title }}</span>
              <div
                v-if="filters[7].selected != null"
                :class="`${filters[7].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[7].selected.length ? filters[7].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[8].selected === null ? 'py-75' : 'py-50'} ${filters[8].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(8)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[8].title }}</span>
              <div
                v-if="filters[8].selected != null"
                :class="`${filters[8].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[8].selected.length ? filters[8].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[9].selected === null ? 'py-75' : 'py-50'} ${filters[9].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(9)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[9].title }}</span>
              <div
                v-if="filters[9].selected != null"
                :class="`${filters[9].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[9].selected.length ? filters[9].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[10].selected === null ? 'py-75' : 'py-50'} ${filters[10].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(10)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[10].title }}</span>
              <div
                v-if="filters[10].selected != null"
                :class="`${filters[10].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[10].selected.length ? filters[10].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[2].selected === null ? 'py-75' : 'py-50'} ${filters[2].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(2)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[2].title }}</span>
              <div
                v-if="filters[2].selected != null"
                :class="`${filters[2].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[2].selected.length ? filters[2].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[3].selected === null ? 'py-75' : 'py-50'} ${filters[3].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(3)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[3].title }}</span>
              <div
                v-if="filters[3].selected != null"
                :class="`${filters[3].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[3].selected.length ? filters[3].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[4].selected === null ? 'py-75' : 'py-50'} ${filters[4].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(4)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[4].title }}</span>
              <div
                v-if="filters[4].selected != null"
                :class="`${filters[4].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[4].selected.length ? filters[4].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[5].selected === null ? 'py-75' : 'py-50'} ${filters[5].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(5)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[5].title }}</span>
              <div
                v-if="filters[5].selected != null"
                :class="`${filters[5].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[5].selected.length ? filters[5].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[6].selected === null ? 'py-75' : 'py-50'} ${filters[6].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(6)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[6].title }}</span>
              <div
                v-if="filters[6].selected != null"
                :class="`${filters[6].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[6].selected.length ? filters[6].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
        </b-col>
        <b-col md="8">
          <div
            v-if="filters[0].options.length > 0"
            :style="`height: ${filters[0].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[0].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[0].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[0].selected && filters[0].selected === option"
                  @change="selectFilter(0, 'Single', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[1].options.length > 0"
            :style="`height: ${filters[1].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[1].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[1].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[1].selected && filters[1].selected === option"
                  @change="selectFilter(1, 'Single', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[2].options.length > 0"
            :style="`height: ${filters[2].open ? 'auto' : '1px' }; overflow: hidden; max-height:400px; overflow-y: scroll;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[2].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[2].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[2].selected && filters[2].selected.indexOf(option) >= 0"
                  @change="selectFilter(2, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[3].options.length > 0"
            :style="`height: ${filters[3].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[3].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[3].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[3].selected && filters[3].selected.indexOf(option) >= 0"
                  @change="selectFilter(3, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[4].options.length > 0"
            :style="`height: ${filters[4].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[4].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[4].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[4].selected && filters[4].selected.indexOf(option) >= 0"
                  @change="selectFilter(4, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[5].options.length > 0"
            :style="`height: ${filters[5].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[5].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[5].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[5].selected && filters[5].selected.indexOf(option) >= 0"
                  @change="selectFilter(5, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[6].options.length > 0"
            :style="`height: ${filters[6].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[6].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[6].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[6].selected && filters[6].selected.indexOf(option) >= 0"
                  @change="selectFilter(6, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[7].options.length > 0"
            :style="`height: ${filters[7].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[7].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[7].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[7].selected && filters[7].selected.indexOf(option) >= 0"
                  @change="selectFilter(7, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[8].options.length > 0"
            :style="`height: ${filters[8].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[8].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[8].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[8].selected && filters[8].selected.indexOf(option) >= 0"
                  @change="selectFilter(8, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filterDeliverables.length > 0"
            :style="`height: ${filters[9].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[9].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filterDeliverables"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[9].selected && filters[9].selected.indexOf(option) >= 0"
                  @change="selectFilter(9, 'Multiple', option)"
                >
                  {{ option.deliverable }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-else
            :style="`height: ${filters[9].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <h5>Not Found</h5>
          </div>
          <div
            v-if="filterCriterion.length > 0"
            :style="`height: ${filters[10].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[10].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filterCriterion"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[10].selected && filters[10].selected.indexOf(option) >= 0"
                  @change="selectFilter(10, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-else
            :style="`height: ${filters[10].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <h5>Not Found</h5>
          </div>
          <div
            v-if="filterIncubators.length > 0"
            :style="`height: ${filters[11].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[11].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filterIncubators"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[11].selected && filters[11].selected.indexOf(option) >= 0"
                  @change="selectFilter(11, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { getUserData } from '@/auth/utils'
import { ChevronsRightIcon } from 'vue-feather-icons'
import gql from 'graphql-tag'
import { Promised } from 'vue-promised'
import {
  BTabs,
  BAlert,
  BAspect,
  BBadge,
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BImg,
  BLink,
  BModal,
  BNavbarNav,
  BNavItemDropdown,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAspect,
    IncubatorDashboard: () => import('@/views/dashboard/v1/pages/IncubatorDashboard.vue'),
    BTabs,
    BCard,
    BCardText,
    BAlert,
    BBadge,
    BCardBody,
    BImg,
    Promised,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BTable,
    BLink,
    BCol,
    BRow,
    BModal,
    ChevronsRightIcon,
    BNavItemDropdown,
    BNavbarNav,
    BFormCheckbox,
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.resource === 'Auth' || getUserData().associatedOrgDetails) {
      next()
    }
    next(false)
  },
  data() {
    return {
      showDismissibleAlert: true,
      open: false,
      isFiltered: false,
      currentHost: window.location.host,
      filters: [
        {
          open: false,
          selected: null,
          title: 'Type',
          options: [
            {
              id: 0,
              title: 'Accelerator Program',
              value: 'Accelerator Program',
            },
            {
              id: 1,
              title: 'Incubation Program',
              value: 'Incubation Program',
            },
            {
              id: 2,
              title: 'Pre-incubation Program',
              value: 'Pre-incubation Program',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Start Date',
          options: [
            {
              id: 0,
              title: '<=1 months',
              value: '1',
            },
            {
              id: 1,
              title: '<=2 months',
              value: '2',
            },
            {
              id: 2,
              title: '<=3 months',
              value: '3',
            },
            {
              id: 3,
              title: '<=6 months',
              value: '6',
            },
            {
              id: 4,
              title: '<=12 months',
              value: '12',
            },
            {
              id: 5,
              title: '>12 months',
              value: '13',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Industry',
          options: [
            {
              id: 0,
              title: 'Agriculture and Allied Industries',
              value: 'Agriculture and Allied Industries',
            },
            {
              id: 1,
              title: 'Automobiles',
              value: 'Automobiles',
            },
            {
              id: 2,
              title: 'Auto Components',
              value: 'Auto Components',
            },
            {
              id: 3,
              title: 'Aviation',
              value: 'Aviation',
            },
            {
              id: 4,
              title: 'Banking',
              value: 'Banking',
            },
            {
              id: 5,
              title: 'Biotechnology',
              value: 'Biotechnology',
            },
            {
              id: 6,
              title: 'Cement',
              value: 'Cement',
            },
            {
              id: 7,
              title: 'Chemicals',
              value: 'Chemicals',
            },
            {
              id: 8,
              title: 'Consumer Durables',
              value: 'Consumer Durables',
            },
            {
              id: 9,
              title: 'Defence Manufacturing',
              value: 'Defence Manufacturing',
            },
            {
              id: 10,
              title: 'E-Commerce',
              value: 'E-Commerce',
            },
            {
              id: 11,
              title: 'Education and Training',
              value: 'Education and Training',
            },
            {
              id: 12,
              title: 'Electronics System Design and Manufacturing',
              value: 'Electronics System Design and Manufacturing',
            },
            {
              id: 13,
              title: 'Engineering and Capital Goods',
              value: 'Engineering and Capital Goods',
            },
            {
              id: 14,
              title: 'Financial Services',
              value: 'Financial Services',
            },
            {
              id: 15,
              title: 'FMCG',
              value: 'FMCG',
            },
            {
              id: 16,
              title: 'Gems and Jewellery',
              value: 'Gems and Jewellery',
            },
            {
              id: 17,
              title: 'Healthcare',
              value: 'Healthcare',
            },
            {
              id: 18,
              title: 'Infrastructure',
              value: 'Infrastructure',
            },
            {
              id: 19,
              title: 'Insurance',
              value: 'Insurance',
            },
            {
              id: 20,
              title: 'IT and BPM',
              value: 'IT and BPM',
            },
            {
              id: 21,
              title: 'Manufacturing',
              value: 'Manufacturing',
            },
            {
              id: 22,
              title: 'Media and Entertainment',
              value: 'Media and Entertainment',
            },
            {
              id: 23,
              title: 'Medical Devices',
              value: 'Medical Devices',
            },
            {
              id: 24,
              title: 'Metals and Mining',
              value: 'Metals and Mining',
            },
            {
              id: 25,
              title: 'MSME',
              value: 'MSME',
            },
            {
              id: 26,
              title: 'Oil and Gas',
              value: 'Oil and Gas',
            },
            {
              id: 27,
              title: 'Pharmaceuticals',
              value: 'Pharmaceuticals',
            },
            {
              id: 28,
              title: 'Ports',
              value: 'Ports',
            },
            {
              id: 29,
              title: 'Power',
              value: 'Power',
            },
            {
              id: 30,
              title: 'Railways',
              value: 'Railways',
            },
            {
              id: 31,
              title: 'Real Estate',
              value: 'Real Estate',
            },
            {
              id: 32,
              title: 'Renewable Energy',
              value: 'Renewable Energy',
            },
            {
              id: 33,
              title: 'Retail',
              value: 'Retail',
            },
            {
              id: 34,
              title: 'Roads',
              value: 'Roads',
            },
            {
              id: 35,
              title: 'Science and Technology',
              value: 'Science and Technology',
            },
            {
              id: 36,
              title: 'Services',
              value: 'Services',
            },
            {
              id: 37,
              title: 'Steel',
              value: 'Steel',
            },
            {
              id: 38,
              title: 'Telecommunications',
              value: 'Telecommunications',
            },
            {
              id: 39,
              title: 'Textiles',
              value: 'Textiles',
            },
            {
              id: 40,
              title: 'Tourism and Hospitality',
              value: 'Tourism and Hospitality',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Life-Cycle',
          options: [
            {
              id: 0,
              title: 'Ideation',
              value: 'Ideation',
            },
            {
              id: 0,
              title: 'Business Modelling',
              value: 'Business Modelling',
            },
            {
              id: 0,
              title: 'Prototyping',
              value: 'Prototyping',
            },
            {
              id: 0,
              title: 'MVP',
              value: 'MVP',
            },
            {
              id: 0,
              title: 'Market Traction',
              value: 'Market Traction',
            },
            {
              id: 0,
              title: 'Product Introduction',
              value: 'Product Introduction',
            },
            {
              id: 0,
              title: 'Revenue Generation',
              value: 'Revenue Generation',
            },
            {
              id: 0,
              title: 'Scaling',
              value: 'Scaling',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Offerings Type',
          options: [
            {
              id: 0,
              title: 'Product',
              value: 'Product',
            },
            {
              id: 1,
              title: 'Service',
              value: 'Service',
            },
            {
              id: 2,
              title: 'Hybrid',
              value: 'Hybrid',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Focus Area',
          options: [
            {
              id: 0,
              title: 'Profit',
              value: 'Profit',
            },
            {
              id: 1,
              title: 'Social Impact',
              value: 'Social Impact',
            },
            {
              id: 2,
              title: 'Environmental Impact',
              value: 'Environmental Impact',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Customer Type',
          options: [
            {
              id: 0,
              title: 'B2C',
              value: 'B2C',
            },
            {
              id: 1,
              title: 'B2B',
              value: 'B2B',
            },
            {
              id: 2,
              title: 'B2G',
              value: 'B2G',
            },
            {
              id: 3,
              title: 'D2C',
              value: 'D2C',
            },
            {
              id: 4,
              title: 'B2B2C',
              value: 'B2B2C',
            },
            {
              id: 5,
              title: 'B2B2B',
              value: 'B2B2B',
            },
            {
              id: 6,
              title: 'B2B2G',
              value: 'B2B2G',
            },
            {
              id: 7,
              title: 'B2G2C',
              value: 'B2G2C',
            },
            {
              id: 8,
              title: 'B2G2B',
              value: 'B2G2B',
            },
            {
              id: 9,
              title: 'B2C2C',
              value: 'B2C2C',
            },
            {
              id: 10,
              title: 'B2C2B',
              value: 'B2C2B',
            },
            {
              id: 11,
              title: 'B2C2G',
              value: 'B2C2G',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Delivery Mode',
          options: [
            {
              id: 0,
              title: 'Hybrid',
              value: 'hybrid',
            },
            {
              id: 1,
              title: 'Offline',
              value: 'offline',
            },
            {
              id: 2,
              title: 'Online',
              value: 'online',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Fundraising Assistance',
          options: [
            {
              id: 0,
              title: 'Demo Day',
              value: 'Demo Day',
            },
            {
              id: 1,
              title: 'Personal Pitching',
              value: 'Personal Pitching',
            },
            {
              id: 2,
              title: 'Liasoning',
              value: 'Liasoning',
            },
            {
              id: 3,
              title: 'None',
              value: 'None',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Program Deliverables',
          options: [],
        },
        {
          open: false,
          selected: null,
          title: 'Shortlisting Criterion',
          options: [],
        },
        {
          open: false,
          selected: null,
          title: 'Incubators',
          options: [],
        },
      ],
      rangeSlider: {
        min: 0,
        max: 120,
      },
      modaldetails: null,
      tab: !this.getIncubatorFromSubdomain() ? 2 : 1,
      programs: [],
      applynow: [],
      filterIncubators: [],
      filterDeliverables: [],
      filterCriterion: [],
      applications: [],
      mentorprograms: [],
      invitations: [],
      incubators: [],
      startupincubators: [],
      startupOrgs: [],
      items: [
        {
          'Organized By': 'xyz', 'Event Title': 'Startup India', 'Event Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023',
        },
      ],
      selectedIndex: null,
      userID: getUserData().id,
      mutationLoading: false,
      selectedOrgId: null,
      userOrgs: getUserData()
        .associatedOrgDetails

        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    advanceSelector(index) {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[index].open = true
    },
    openAdvanceModal() {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[0].open = true
      this.$bvModal.show('filterModal')
    },
    selectFilter(index, mode, value) {
      if (mode === 'Single') {
        if (this.filters[index].selected === value) {
          this.filters[index].selected = null
        } else {
          this.filters[index].selected = value
        }
      } else if (mode === 'Multiple') {
        if (this.filters[index].selected !== null && this.filters[index].selected.indexOf(value) >= 0) {
          const temp = this.filters[index].selected
          temp.splice(temp.indexOf(value), 1)
          this.filters[index].selected = temp
          if (temp.length === 0) {
            this.filters[index].selected = null
          }
        } else {
          const temp = this.filters[index].selected ? this.filters[index].selected : []
          temp.push(value)
          this.filters[index].selected = temp
        }
      }
    },
    getList(index) {
      let string = ''
      if (this.filters[index].selected) {
        this.filters[index].selected.map(list => {
          if (string === '') {
            string = `"${list.value}"`
          } else {
            string = `${string}, "${list.value}"`
          }
          return 0
        })
      }
      return string
    },
    getDeliverableList() {
      let string = ''
      if (this.filters[9].selected) {
        this.filters[9].selected.map(list => {
          if (string === '') {
            string = `"${list.id}"`
          } else {
            string = `${string}, "${list.id}"`
          }
          return 0
        })
      }
      return string
    },
    getCriterionList() {
      let string = ''
      if (this.filters[10].selected) {
        this.filters[10].selected.map(list => {
          if (string === '') {
            string = `"${list.id}"`
          } else {
            string = `${string}, "${list.id}"`
          }
          return 0
        })
      }
      return string
    },
    getIncubatorList() {
      let string = ''
      if (this.filters[11].selected) {
        this.filters[11].selected.map(list => {
          if (string === '') {
            string = `"${list.id}"`
          } else {
            string = `${string}, "${list.id}"`
          }
          return 0
        })
      }
      return string
    },
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              user_id: ${getUserData().id},
              incubator_id: ${incubatorId},
              role: "mentor",
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
          this.$apollo.queries.incubators.refetch()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong. Please try again later.',
              message: error.message,
              icon: 'CompassIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.programs[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.programs[this.selectedIndex].id}, designation: ${this.programs[this.selectedIndex].users_associationtables[0].designation} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
    updateInvResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.invitations[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.invitations[this.selectedIndex].id}, designation: ${this.invitations[this.selectedIndex].users_associationtables[0].designation}, is_jury: ${this.invitations[this.selectedIndex].users_associationtables[0].is_jury} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          this.$apollo.queries.invitations.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    programs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
          programs_basicinfo(
            order_by: {id: desc},
            where: {
              ${this.filters[0].selected ? `
                type: {_eq: "${this.filters[0].selected.value}"},
              ` : ''}
              ${this.filters[1].selected ? `
                computed_remaining_months: {${this.filters[1].selected.value !== 13 ? `_lte: "${this.filters[1].selected.value}"` : `_gt: "${this.filters[1].selected.value}"`}},
              ` : ''}
              ${this.filters[2].selected ? `
                industry: {_in: [${this.getList(2)}]},
              ` : ''}
              ${this.filters[3].selected ? `
                ideal_lifecycle_stage: {_in: [${this.getList(3)}]},
              ` : ''}
              ${this.filters[4].selected ? `
                offerings_type: {_in: [${this.getList(4)}]},
              ` : ''}
              ${this.filters[5].selected ? `
                focus_type: {_in: [${this.getList(5)}]},
              ` : ''}
              ${this.filters[6].selected ? `
                customer_type: {_in: [${this.getList(6)}]},
              ` : ''}
              ${this.filters[7].selected ? `
                delivery_mode: {_in: [${this.getList(7)}]},
              ` : ''}
              ${this.filters[8].selected ? `
                fund_raising_assistance: {_in: [${this.getList(8)}]},
              ` : ''}
              ${this.filters[9].selected ? `
                programs_deliverablelisttables: {_in: [${this.getDeliverableList()}]},
              ` : ''}
              ${this.filters[10].selected ? `
                programs_shortlistingcriteriontables: {_in: [${this.getCriterionList()}]},
              ` : ''}
              ${this.filters[11].selected ? `
                organization_id_id: {_in: [${this.getIncubatorList()}]},
              ` : ''}
              users_organizationtable: {domain: {_regex: "${subdomain}"}},
              is_event: {_eq: true},
              programs_startupparticipants: {programs_applicantstable: ${getUserData().associatedOrgDetails.length > 0 ? `{organization_id_id: {_in: [${this.userOrgs}]}}` : `{user_id: {_in: [${this.userID}]}}`}}
            },
            distinct_on: id
          ) {
            id
            title
            begin_date
            end_date
            image
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applynow: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
          programs_basicinfo(
            order_by: {id: asc},
            where: {
              ${this.filters[0].selected ? `
                type: {_eq: "${this.filters[0].selected.value}"},
              ` : ''}
              ${this.filters[1].selected ? `
                computed_remaining_months: {${this.filters[1].selected.value !== 13 ? `_lte: "${this.filters[1].selected.value}"` : `_gt: "${this.filters[1].selected.value}"`}},
              ` : ''}
              ${this.filters[2].selected ? `
                industry: {_in: [${this.getList(2)}]},
              ` : ''}
              ${this.filters[3].selected ? `
                ideal_lifecycle_stage: {_in: [${this.getList(3)}]},
              ` : ''}
              ${this.filters[4].selected ? `
                offerings_type: {_in: [${this.getList(4)}]},
              ` : ''}
              ${this.filters[5].selected ? `
                focus_type: {_in: [${this.getList(5)}]},
              ` : ''}
              ${this.filters[6].selected ? `
                customer_type: {_in: [${this.getList(6)}]},
              ` : ''}
              ${this.filters[7].selected ? `
                delivery_mode: {_in: [${this.getList(7)}]},
              ` : ''}
              ${this.filters[8].selected ? `
                fund_raising_assistance: {_in: [${this.getList(8)}]},
              ` : ''}
              ${this.filters[9].selected ? `
                programs_deliverablelisttables: {_in: [${this.getDeliverableList()}]},
              ` : ''}
              ${this.filters[10].selected ? `
                programs_shortlistingcriteriontables: {_in: [${this.getCriterionList()}]},
              ` : ''}
              ${this.filters[11].selected ? `
                organization_id_id: {_in: [${this.getIncubatorList()}]},
              ` : ''}
              users_organizationtable: {domain: {_regex: "${subdomain}"}},
              programs_applicationformstables: {
                status: {_eq: "enabled"},
                round_number: {_eq: 1}
              },
              is_event: {_eq: true},
            },
            distinct_on: id
          ) {
            begin_date
            end_date
            capacity
            description
            id
            image
            industry
            title
            type
            users_organizationtable {
              logo
              title
              type
            }
            programs_applicationformstables(where: {round_number: {_eq: 1}, status: {_eq: "enabled"}}) {
              id
            }
            programs_applicantstables(where: {${getUserData().associatedOrgDetails.length > 0 ? `organization_id_id: {_in: [${this.userOrgs}]}` : `user_id: {_in: [${this.userID}]}`}}) {
              current_application_form_id
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applications: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(
              order_by: {id: asc},
              where: {
                ${this.filters[0].selected ? `
                  type: {_eq: "${this.filters[0].selected.value}"},
                ` : ''}
                ${this.filters[1].selected ? `
                  computed_remaining_months: {${this.filters[1].selected.value !== 13 ? `_lte: "${this.filters[1].selected.value}"` : `_gt: "${this.filters[1].selected.value}"`}},
                ` : ''}
                ${this.filters[2].selected ? `
                  industry: {_in: [${this.getList(2)}]},
                ` : ''}
                ${this.filters[3].selected ? `
                  ideal_lifecycle_stage: {_in: [${this.getList(3)}]},
                ` : ''}
                ${this.filters[4].selected ? `
                  offerings_type: {_in: [${this.getList(4)}]},
                ` : ''}
                ${this.filters[5].selected ? `
                  focus_type: {_in: [${this.getList(5)}]},
                ` : ''}
                ${this.filters[6].selected ? `
                  customer_type: {_in: [${this.getList(6)}]},
                ` : ''}
                ${this.filters[7].selected ? `
                  delivery_mode: {_in: [${this.getList(7)}]},
                ` : ''}
                ${this.filters[8].selected ? `
                  fund_raising_assistance: {_in: [${this.getList(8)}]},
                ` : ''}
                ${this.filters[9].selected ? `
                  programs_deliverablelisttables: {_in: [${this.getDeliverableList()}]},
                ` : ''}
                ${this.filters[10].selected ? `
                  programs_shortlistingcriteriontables: {_in: [${this.getCriterionList()}]},
                ` : ''}
                ${this.filters[11].selected ? `
                  organization_id_id: {_in: [${this.getIncubatorList()}]},
                ` : ''}
                users_organizationtable: {domain: {_regex: "${subdomain}"}},
                is_event: {_eq: true},
                programs_applicantstables: ${getUserData().associatedOrgDetails.length > 0 ? `{organization_id_id: {_in: [${this.userOrgs}]}}` : `{user_id: {_in: [${this.userID}]}}`}
              },
              distinct_on: id
            ) {
            id
            begin_date
            end_date
            capacity
            description
            image
            industry
            organization_id_id
            title
            total_shortlisting_rounds
            type
            users_organizationtable {
              logo
              title
              type
            }
            programs_applicantstables(where: {${getUserData().associatedOrgDetails.length > 0 ? `organization_id_id: {_in: [${this.userOrgs}]}` : `user_id: {_in: [${this.userID}]}`}}) {
              current_application_form_id
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorprograms: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
            programs_basicinfo(order_by: {id: desc}, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${getUserData().id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                programs_partnerstables(where: {user_id: {_eq: ${getUserData().id}}}) {
                  id
                  is_jury
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
    invitations: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userID} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userID} }}) {
                id
                designation
                status
                role
                is_jury
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
    incubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}, type: {_ilike: "incubator"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {user_id: {_eq: ${getUserData().id} }}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => {
        if (!data.users_organizationtable.usersAssociationtablesByAssociatedOrg) {
          // this.$bvModal.show('join-incubator-modal')
        }
        return data.users_organizationtable
      },
    },
    startupincubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {type: {_ilike: "incubator"}, domain: {_regex: "${subdomain}"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: ${this.selectedOrgId} }}) @include(if: ${!!this.selectedOrgId}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => data.users_organizationtable,
    },
    startupOrgs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query startup_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${getUserData().id}"}}})
            {
              id
              title
              users_associationtables(where: {usersOrganizationtableByIncubator: {domain: {_regex: "${subdomain}"}}, role: { _in: ["startup","incubatee","preincubatee","graduate"] }}) {
                id
              }
            }
        }`
      },
      update: data => {
        const temp = []
        data.users_organizationtable.forEach(orgs => {
          if (orgs.users_associationtables.length > 0) {
            temp.push(orgs)
          }
        })
        return temp
      },
    },
    filterIncubators: {
      query() {
        return gql`
        query filterIncubators {
          users_organizationtable(where: {type: {_eq: "Incubator"}}) {
            id
            title
          }
        }`
      },
      update: data => data.users_organizationtable,
    },
    filterDeliverables: {
      query() {
        return gql`
        query filterDeliverables {
          programs_deliverablelisttable(distinct_on: deliverable, where: {programs_basicinfo: {begin_date: {_gt: "${(new Date().toUTCString())}"}, is_event: {_eq: true}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}}}) {
            deliverable
            id
          }
        }`
      },
      update: data => data.programs_deliverablelisttable,
    },
    filterCriterion: {
      query() {
        return gql`
        query filterCriterion {
          programs_shortlistingcriteriontable(where: {programs_basicinfo: {begin_date: {_gt: "${(new Date().toUTCString())}"}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}, is_event: {_eq: true}}}) {
            criteria
            id
          }
        }`
      },
      update: data => data.programs_shortlistingcriteriontable,
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
.selected-top-tab{
  background-color: #7367F0;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
.deselected-top-tab{
  background-color: #F1F1F3;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
</style>

<style>
  .dropdown-toggle::after {
    display:none;
  }
  .filters-box{
    border-top: solid 1px #d3d3d3;
    border-bottom: solid 1px #d3d3d3;
  }
  .fl-col-1{
    width:fit-content;
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }
  .fl-col-2{
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-left: solid 1px #d3d3d3;
    border-right: solid 1px #d3d3d3;
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: auto;
    overflow-y: visible; */
  }
  .fl-col-2::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .fl-col-2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .fl-col-2::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  .filter-holder{
    display: flex;
  }
  .fl-col-3{
    width:fit-content;
    padding-left: 1rem;
    display: flex;
    align-items: center;
  }
</style>
